/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core'
import Page, { PageMeta } from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import React, { useState } from 'react'
import { createGlobalStyle } from 'styled-components'

import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits, formatEther } from '@ethersproject/units'
import { getContractHandler } from 'hooks/ethereum'
import numeral from 'numeral'
import Script from 'react-load-script'

import { Constants } from 'hooks/WolfConfig'

const MintStyle = createGlobalStyle`

 


@media screen and (min-width:968px) {

  .home_body_phone{
    display:none;
  }
 
  .nft_transfer{
        top:-120px;
        left:-25%;
        width:20%;
  }

  .nft_transfer_h1{
    top:-180px; 
    width:20%;
    left: 15%;
    color:#fff;
    // font-family:HYHeiFang;
  }

  .nft_transfer_h2{
    top:-165px; 
    width:20%;
    left: 15%;
    color:#fff;
    font-family:HYHeiFang;
  }

  .home_head2{
    width:1155px;
    text-align:center;
    position:relative ;  
    top:-80px;
  }

  .home_head2 img{
    position:relative ;  
    float:left;
    
   }
 
   .home_head2 h1{ 
     position:relative ;  
     float:left;
     width:195px;
     text-align:center;
     margin-right:10px;
    }

    .TINC{
      margin-right:10px;

       width:195px;
       height:91px;
    }
    .TINC_h1{
      color:#673a1d;
      font-family:HYHeiFang;
      font-size:80%;
      top:-70px;
    }
    .TINC_h2{
      top:-50px;
      font-family:HYHeiFang;
      font-size:100%;
      color:#fff; 
      font-weight: bold; 
    }

    .TINC_h3{
      width:50px!important;
      top:-50px;
      font-family:HYHeiFang;
      font-size:100%;
      color:#fbfa16; 
      font-weight: bold; 
      left:40px;
    }
    .TINC_h4{
      width:50px!important;
      top:-50px;
      left:40px;
      font-family:HYHeiFang;
      font-size:100%;
      color:#673a1d;  
      font-weight: bold; 
    }



    .home_head3{
      width:1155px;
      text-align:center;
      position:relative ;  
      top:-120px; 
      left:10%;
    }



    .home_head3 ul{ list-style: none; }


      .poster-main {
        position: relative;

      }

      .home_head3  .poster-main a, .poster-main img { display: block; width: 100%; height: 100%;}
      .poster-main img{ }
      .poster-main .poster-list {
        width: 1000px;
        height: 500px;
      }

      .home_head3 .poster-main .poster-list .poster-item {
        width: 200px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 0;
      
      }

      .home_head3  .poster-main .poster-btn {
        position: absolute;
        top: 0;
        cursor: pointer;
        filter: opacity(.5) brightness(1);
      }
      .home_head3  .poster-main .poster-btn:hover{ filter: opacity(1) brightness(2); }

      .home_head3  .poster-main .poster-prev-btn {
        left: 0;
        background: url(/images/home/wolf/left.png) no-repeat center center;
      }

      .home_head3  .poster-main .poster-next-btn {
        right: 0;
        background: url(/images/home/wolf/right.png) no-repeat center center;
      }
      .home_head3  .poster-item-title{
        font-size: 18px;
        text-align: center;
        line-height: 48px;
        display: block;
        font-family:HYHeiFang;
      }

      .poster-list li{
        border-width:5px; 
         border-style:solid;
         border-color:#ff9454;
         box-shadow: 0 0 5px 1px black; 
      }



      .home_head4{
        width:113%;
        text-align:center;
        position:relative ;  
        top:-300px; 
        left:-5.9%;
      }

      .home_tz{
        width:100%;
        left:0px;
      }


      .tz_h1{
        color:#fff;
        // font-family:HYHeiFang;
        position:relative ;  
        font-size:100%;
        top:-405px;
      }


      .home_head5{
        width:80%;
        text-align:center;
        position:relative ;  
        top:-320px; 
        left:18%;
      }


      .home_tz1{
        width:15%;
        margin-right:8%;
        float:left;
        position:relative ;  
         
      }

      .home_tz2{
        width:15%;
        margin-right:8%;
        float:left;
        position:relative ;  
        
      }
    
}






/* 手机端 */
@media screen and (max-width:968px) {

  .home_body{
    display:none;
    
  }

  .home_body_phone{
    padding-left:0px!important;
    padding-right:0px!important;
    text-align:center;
  }

  
  .home_head_phone{ 
    padding-top:30px!important;
    padding-bottom:30px!important;
    position:relative;
    width:100%; 
    text-align:center;
    height:cacl(100% * 0.65)!important;
    background-image:url("/images/home/wolf_phone/zhutu.png");
    overflow:hidden;
  }
 
  .wolf_phone{
    position:relative;
    float:right;
    width:40%;
    margin-bottom:10px;
  }

  .wolf_phone_wolftowm{
    position:relative;
    float:left;
    width:30%;
    margin-left:15%; 
    margin-top:20%; 
  }

  .wolf_phone_play{
    position:relative;
    float:left;
    width:40%;
    margin-top:5%; 
    margin-left:10%; 
  }

  .home_head_tinc{
    position:relative;
    float:left;
    width:100%;
    top:32px;
  }

  .home_head_left{
    position:relative;
    float:left;
    width:48%;
    padding-top:30px!important;
    padding-bottom:30px!important;
    background-image:url("/images/home/wolf_phone/tinc_left.png");
    margin-top:-15px;
   
  }


  .home_head_right{
    position:relative;
    float:left;
    width:45%;
    padding-top:30px!important;
    padding-bottom:30px!important;
    margin-left:15px!important;
    background-image:url("/images/home/wolf_phone/tinc_right.png");
  }
    
  .home_head_h1{
     position:relative ;  
     float:left;
     width:195px;
     text-align:center;
     margin-right:10px;
     color: #673a1d; 
  }
  
  .home_head_h2{
    position:relative ;  
    float:left;
    width:195px;
    text-align:center;
    margin-right:10px;
    margin-top:10px;
    color:#FFF;
    font-family:HYHeiFang;
 }


 .home_head_bottom{
    position:relative ;  
    width:100%; 
    float:left;
    background-color: #fec435  ; 

    padding-top:3px!important;
    padding-bottom:3px!important;
 }
 
   



    .home_head2_phone{
      margin-top:10px;
      text-align:center;
      position:relative ;  
      width:100%;
      height:50px;
      background-image:url("/images/home/wolf_phone/earn.png");
      padding-left:auto;
      padding-right:auto; 
      background-repeat: no-repeat; 
      background-size: 100% 100%;
    }

    .home_head2_phone img{ 
      width:20%;
      float:left;
      margin-top:10px;
    }

    .home_head_phone_h2{ 
      float:left;
      width:60%;  
      margin-left:20px;
      margin-top:20px;
    }


 


    .home_head3 ul{ list-style: none; }


      .poster-main2 {
        position: relative;

      }

      .home_head3  .poster-main2 a, .poster-main2 img { display: block; width: 100%; height: 100%;}
      .poster-main2 img{ }
      .poster-main2 .poster-list {
        width: 1000px;
        height: 500px;
      }

      .home_head3 .poster-main2 .poster-list .poster-item {
        width: 200px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 0;
      
      }

      .home_head3  .poster-main2 .poster-btn {
        position: absolute;
        top: 0;
        cursor: pointer;
        filter: opacity(.5) brightness(1);
      }
      .home_head3  .poster-main2 .poster-btn:hover{ filter: opacity(1) brightness(2); }

      .home_head3  .poster-main2 .poster-prev-btn {
        left: 0;
        background: url(/images/home/wolf/left.png) no-repeat center center;
      }

      .home_head3  .poster-main2 .poster-next-btn {
        right: 0;
        background: url(/images/home/wolf/right.png) no-repeat center center;
      }
      .home_head3  .poster-item-title{
        font-size: 18px;
        text-align: center;
        line-height: 48px;
        display: block;
        font-family:HYHeiFang;
      }

      .poster-list li{
        border-width:5px; 
         border-style:solid;
         border-color:#ff9454;
         box-shadow: 0 0 5px 1px black; 
      }



      .home_head4{
        width:113%;
        text-align:center;
        position:relative ;  
        top:-300px; 
        left:-5.9%;
      }

      .home_tz{
        width:100%;
        left:0px;
      }


      .tz_h1{
        color:#fff;
        font-family:HYHeiFang;
        position:relative ;  
        font-size:100%;
        top:-405px;
      }


      .home_head5{
        width:80%;
        text-align:center;
        position:relative ;  
        top:-320px; 
        left:18%;
      }


      .home_tz1{
        width:15%;
        margin-right:8%;
        float:left;
        position:relative ;  
         
      }

      .home_tz2{
        width:15%;
        margin-right:8%;
        float:left;
        position:relative ;  
        
      }
    
}



`

// 报错 但有用
const handleScriptLoad = () => {
  ;($('.poster-main') as any).Carousel({
    width: 900,
    height: 628,
    posterWidth: 450,
    posterHeight: 252,
    scale: 0.7,
    speed: 1000,
    autoPlay: false,
    delay: 3500,
    verticalAlign: 'middle',
  })
}

// 报错 但有用
const handleScriptLoad2 = () => {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;($('.poster-main2') as any).Carousel({
    width: 400,
    height: 280,
    posterWidth: 250,
    posterHeight: 140,
    scale: 0.7,
    speed: 1000,
    autoPlay: false,
    delay: 3500,
    verticalAlign: 'middle',
  })
}

const ScriptObj2: React.FC = () => {
  return <Script url="/js/Carousel.js" onLoad={handleScriptLoad} />
}

const ScriptObj3: React.FC = () => {
  return <Script url="/js/Carousel.js" onLoad={handleScriptLoad2} />
}

const Home: React.FC = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()
  const { t } = useTranslation()

  const [totalSupply, settotalSupply] = useState('0')
  const [usdtLpOfWtwool, setUsdtLpOfWtwool] = useState(Number)
  const [bnbLpOfWtwool, setBnbLpOfWtwool] = useState(Number)
  const [wtwoolPrice, setWtwoolPrice] = useState(Number)
  const [LPOfWtwool, setLPOfWtwool] = useState(Number)
  const [swapWtwool, setSwapWtwool] = useState(Number)
  const [zeroOfWtwool, setZeroOfWtwool] = useState(Number)

  // 获取NFT总量
  const getTotalSupply = async () => {
    const contract = await getContractHandler('WTAnimal')
    console.log('contract', contract)
    const total = await contract.totalSupply()

    const totalResult = formatUnits(total, 0)
    // 输出

    settotalSupply(totalResult)
  }

  // 获取LP中羊毛代币数量
  const balanceOfWTWool = async () => {
    if (account == null) return
    const contract = await getContractHandler('WTWool')
    const totalForUsdt: BigNumber = await contract.balanceOf(Constants.Contract.WT_LP_USDT)
    const totalForBnb: BigNumber = await contract.balanceOf(Constants.Contract.WT_LP_BNB)
    const zeroForBnb: BigNumber = await contract.balanceOf('0x000000000000000000000000000000000000dead')

    const usdtContract = await getContractHandler('USDT')
    const usdtNumber: BigNumber = await usdtContract.balanceOf(Constants.Contract.WT_LP_USDT)

    // 计算单币价格

    console.log('usdtNumber', +formatEther(usdtNumber))
    console.log('totalForUsdt', +formatEther(totalForUsdt))

    const price = +formatEther(usdtNumber) / +formatEther(totalForUsdt)

    console.log('price', price)

    setWtwoolPrice(price)

    setUsdtLpOfWtwool(+formatEther(totalForUsdt))
    setBnbLpOfWtwool(+formatEther(totalForBnb))

    setLPOfWtwool(+formatEther(totalForBnb.add(totalForUsdt)))
    // 获取销毁量
    setZeroOfWtwool(+formatEther(zeroForBnb))

    const zero = formatEther(zeroForBnb)

    setSwapWtwool(5000000000 - +zero)

    await getTotalSupply()
  }

  // 引入JS

  balanceOfWTWool()

  return (
    <>
      <PageMeta />

      <MintStyle />
      <Page className="home_body" style={{ height: '1350px' }}>
        <div className="home_head">
          <img src="/images/home/wolf/home_top.png" alt="home" />
          <img className="nft_transfer" src="/images/home/wolf/nft_transfer.png" alt="home" />
          <h1 className="nft_transfer_h1">{t('Total NFT')}</h1>
          <h1 className="nft_transfer_h2">{numeral(totalSupply).format('0,0')} </h1>
        </div>

        <div className="home_head2">
          <img className="TINC" src="/images/home/wolf/TINC.png" alt="" />
          <img className="TINC" src="/images/home/wolf/TINC.png" alt="" />
          <img className="TINC" src="/images/home/wolf/TINC.png" alt="" />
          <img className="TINC" src="/images/home/wolf/TINC.png" alt="" />
          <img src="/images/home/wolf/GO.png" alt="" />

          <h1 className="TINC_h1">{t('WTWOOL Market Value')}</h1>
          <h1 className="TINC_h1">{t('WTWOOL Circulation')}</h1>
          <h1 className="TINC_h1">{t('WTWOOL Destroyed')}</h1>
          <h1 className="TINC_h1">{t('Bank Funds')}</h1>
          <h1 className="TINC_h1">{t('Earn Money')}</h1>

          <h1 className="TINC_h2"> $ {numeral(LPOfWtwool * wtwoolPrice).format('0,0')} </h1>
          <h1 className="TINC_h2"> $ {numeral(swapWtwool).format('0,0')} </h1>
          <h1 className="TINC_h2"> $ {numeral(zeroOfWtwool).format('0,0')} </h1>
          <h1 className="TINC_h2"> $ {numeral(LPOfWtwool).format('0,0')} </h1>
          <h1 className="TINC_h3"> {t('300% ')} </h1>
          <h1 className="TINC_h4"> {t('APR')} </h1>
        </div>

        <div className="home_head3">
          <div className="poster-main">
            <div className="poster-btn poster-prev-btn" />
            <ul className="poster-list">
              <li className="poster-item">
                <a href="#">
                  <img src="/images/home/wolf/a1.jpg" alt="" />
                </a>
                <span className="poster-item-title">WOLF TOWN</span>
              </li>
              <li className="poster-item">
                <a href="#">
                  <img src="/images/home/wolf/a2.jpg" alt="" />
                </a>
                <span className="poster-item-title">WOLF TOWN</span>
              </li>
              <li className="poster-item">
                <a href="#">
                  <img src="/images/home/wolf/a3.jpg" alt="" />
                </a>
                <span className="poster-item-title">WOLF TOWN</span>
              </li>
            </ul>
            <div className="poster-btn poster-next-btn" />
          </div>
        </div>

        <ScriptObj2 />

        <div className="home_head4">
          <img className="home_tz" src="/images/home/wolf/tz.png" alt="home" />
          <h1 className="tz_h1"> {t('Cooperative Partners')} </h1>
          <div className="home_head5">
            {/*   <img className="home_tz1" src="/images/home/wolf/111.png" alt="home" /> */}
            <img className="home_tz1" src="/images/home/wolf/116.png" alt="home" />
            {/*             <img className="home_tz1" src="/images/home/wolf/113.png" alt="home" /> */}
            <img className="home_tz1" src="/images/home/wolf/114.png" alt="home" />

            {/*   <img className="home_tz2" src="/images/home/wolf/115.png" alt="home" />
            <img className="home_tz2" src="/images/home/wolf/116.png" alt="home" />
            <img className="home_tz2" src="/images/home/wolf/117.png" alt="home" />
            <img className="home_tz2" src="/images/home/wolf/118.png" alt="home" /> */}
          </div>
        </div>
      </Page>

      <Page className="home_body_phone" style={{}}>
        <div className="home_head_phone">
          <img className="wolf_phone" src="/images/home/wolf_phone/wolf.png" alt="" />
          <img className="wolf_phone_wolftowm" src="/images/home/wolf_phone/wolftowm.png" alt="" />
          <a href="https://game.wolftown.games/" target={'_blank'}>
            <img className="wolf_phone_play" src="/images/home/wolf_phone/play.png" alt="" />
          </a>
          <div className="home_head_tinc">
            <div className="home_head_left">
              <h1 className="home_head_h1"> {t('-')} </h1>
              <h1 className="home_head_h1"> {t('WTWOOL Market Value')} </h1>
              <h1 className="home_head_h2"> $ {numeral(LPOfWtwool * wtwoolPrice).format('0,0')} </h1>
            </div>
            <div className="home_head_right">
              <h1 className="home_head_h1">{t('WTWOOL Circulation')} </h1>
              <h1 className="home_head_h2"> $ {numeral(swapWtwool).format('0,0')} </h1>
            </div>
            <div className="home_head_bottom">
              <h1 className="home_head_h1">{t('WTWOOL Destroyed')} </h1>
              <h1 className="home_head_h2" style={{ top: '-10px' }}>
                {' '}
                $ {numeral(zeroOfWtwool).format('0,0')}{' '}
              </h1>
            </div>
          </div>
        </div>

        <div className="home_head2_phone">
          <h1 className="home_head_phone_h2">{t('Land Mint ')}</h1>
          <a href="/land">
            <img src="/images/home/wolf_phone/go.png" alt="gogo" />
          </a>
        </div>

        <div className="home_head2_phone">
          <h1 className="home_head_phone_h2">{t('White Paper')}</h1>
          <a target={'_blank'} href="https://gamefi-zhen-tan-she.gitbook.io/wolftowm/">
            <img src="/images/home/wolf_phone/go.png" alt="gogo" />
          </a>
        </div>

        <div className="home_head3">
          <div className="poster-main2">
            <div className="poster-btn poster-prev-btn" />
            <ul className="poster-list">
              <li className="poster-item">
                <a href="#">
                  <img src="/images/home/wolf/a1.jpg" alt="" />
                </a>
                <span className="poster-item-title">WOLF TOWN</span>
              </li>
              <li className="poster-item">
                <a href="#">
                  <img src="/images/home/wolf/a2.jpg" alt="" />
                </a>
                <span className="poster-item-title">WOLF TOWN</span>
              </li>
              <li className="poster-item">
                <a href="#">
                  <img src="/images/home/wolf/a3.jpg" alt="" />
                </a>
                <span className="poster-item-title">WOLF TOWN</span>
              </li>
            </ul>
            <div className="poster-btn poster-next-btn" />
          </div>
        </div>

        <ScriptObj3 />
      </Page>
    </>
  )
}

export default Home
